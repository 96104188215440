import { getNumericPrice } from "../../../utils/productsHelper"

export const MAX_PRICE_OPTIONS = [
  { 1000: true },
  { 1500: true },
  { 2500: true },
  { 5000: true },
  { 7500: true },
  { 10000: true },
  { 12500: true },
  { 15000: true }
]

export const updateSearchUrl = (brand, priceMax) => {
  const urlBase =
    !brand.name || brand.name === "Any" ? `/all-bikes` : `/brands/${brand.slug}`
  const url =
    priceMax && priceMax !== "Any" ? `${urlBase}?priceMax=${priceMax}` : urlBase

  return url
}

export const getPriceKey = priceObject => {
  return Object.keys(priceObject).reduce((_, value) => value)
}

export const getBrandPrices = brandProducts => {
  return brandProducts
    .reduce((prev, currentProduct) => {
      const price = getNumericPrice(currentProduct.price)
      return [...prev, Math.ceil(price)]
    }, [])
    .sort()
}

export const getAvailableMinPrices = (priceOptions, lowestAvailablePrice) => {
  return priceOptions.map(item => {
    const price = getPriceKey(item)
    if (price >= lowestAvailablePrice) {
      return item
    }
    return { [price]: false }
  })
}

export const getBrandModels = brandProducts => {
  return brandProducts?.nodes.reduce(
    (prev, currentProduct) => [...prev, ...currentProduct.allPaModel.nodes],
    []
  )
}

export const getModelBrand = (model, brands) => {
  const brandArray = brands.filter(brand => {
    const modelBrandName = model?.products?.nodes[0]?.allPaBrand?.nodes[0].name
    if (brand.name === modelBrandName) {
      return brand
    }
  })

  const brand = brandArray.length ? brandArray.reduce((_, value) => value) : {}

  return brand
}

export const moveElementById = (arr, name, toIndex) =>{
  arr = arr.filter(e => e);

  let elementExist = arr.some( el => el.name === name)
  if(elementExist){

    const currentIndex = arr.findIndex(el => el.name == name)
  if(currentIndex === toIndex ) return arr

  const fromIndex = arr.findIndex(el => el.name == name)
  var element = arr[fromIndex];

  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}
return arr
}