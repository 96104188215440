import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import ProductBrandFilter from "./ProductBrandFilter"

export const fragment = graphql`
  fragment ProductBrandFilterFragment on WpPage_Flexlayouts_FlexibleLayouts_ProductBrandFilter {
    sectionHeading
    text
    button {
      url
      target
      title
    }
    # config {
    #   padding {
    #     top {
    #       topMobile
    #       topTablet
    #       topDesktop
    #     }
    #     bottom {
    #       bottomMobile
    #       bottomTablet
    #       bottomDesktop
    #     }
    #   }
    # }
  }
`

const SEARCH_FILTER_QUERY = graphql`
  query SEARCH_FILTER_QUERY_CEB {
   
      allWpPaBrand(limit: 50) {
        nodes {
          slug
          name
        }
      }
      # allWpPaModel(first: 50) {
      #   nodes {
      #     slug
      #     name
      #   }
      # }
      allWpProductCategory(limit: 50) {
        nodes {
          name
          wpChildren {
            nodes {
              name
              slug
            }
          }
        }
      }
    
  }
`

export const ACFProductBrandFilter = ({ sectionHeading, button, config }) => {
  const data = useStaticQuery(SEARCH_FILTER_QUERY)

  const {
    allWpPaBrand: { nodes: brands },
    allWpProductCategory: { nodes: categories },
    // allWpPaModel: { nodes: models },
  } = data

  return (
    <>
      {/* <ProductBrandFilter
        searchFilters={{ brands, categories }}
        sectionHeading={sectionHeading}
        text={text}
        button={button}
        config={config}
      /> */}
    </>
  )
}
